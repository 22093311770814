import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation, } from 'react-router-dom';
import firebaseConfig from './config/firebase_config.json';
import { initializeApp } from "firebase/app";
import config from './config/env.json';
import './css/App.css';

// Unlogged-ONLY Pages
import Page_Login from './Page_Login';
import Page_Login_otp from './Page_Login_otp';

// Logged-ONLY Pages
import Structure_logged from './Structure_logged';
import Page_Dashboard from './Page_Dashboard';
import Page_Customers from './Page_Customers';
import Page_Charts from './Page_Charts';
import Page_Pl from './Page_Pl';
import Page_Masteraccount from './Page_Masteraccount';
import Page_Kycstatus from './Page_Kycstatus';
import Page_Infrastructurestatus from './Page_Infrastructurestatus';
import Page_Maintenance from './Page_Maintenance';
import Page_Unlimit from './Page_Unlimit';
import Page_Epay from './Page_Epay';
import Page_DailyOutputs from './Page_DailyOutputs';
import Page_SuperAdminOperations from './Page_SuperAdminOperations';
import Page_Suppliers from './Page_Suppliers';

const RedirectIfNoCookie = () => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('YourCookieName='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
        return <Navigate to="/login" />;
    } else {
        return null;
    }
}

const LanguageFlagWrapper = () => {
    const location = useLocation();

    switch (location.pathname) {
        case "/login":
        case "/login_otp":
            break;
        default:
            return null; // Return nothing for any other routes
    }

    return (
        <>  </>
    );
};

const SessionManager = () => {
    const navigate = useNavigate();

    // This function will set a new cookie with the updated expiration time
    const resetSessionTimer = () => {
        // Set the expiration time to 5 minutes from the current time
        const newExpirationTime = new Date(new Date().getTime() + config.INACTIVITY_LOGOUT_TIME_IN_MINUTES * 60000).toUTCString();
        setCookieValue('inactivity_session_expiration_time', newExpirationTime);
        console.log('Session reset at:', newExpirationTime);
    };

    useEffect(() => {
        // This function is called when user interaction is detected
        const handleUserAction = () => {
            resetSessionTimer(); // Reset the session timer upon user action
        };

        // Set up event listeners for user interaction
        window.addEventListener('click', handleUserAction);
        window.addEventListener('keydown', handleUserAction);

        // This interval will check for session expiration
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();

            // Get the stored cookie values for firebase and inactivity expiration
            const firebaseExpirationTime = getCookieValue('firebase_session_expiration_time');
            const inactivityExpirationTime = getCookieValue('inactivity_session_expiration_time');

            // Calculate time left before expiration
            const firebaseTimeLeft = (new Date(firebaseExpirationTime).getTime() - currentTime) / 1000;
            const inactivityTimeLeft = (new Date(inactivityExpirationTime).getTime() - currentTime) / 1000;

            // Check if either session has expired
            if (firebaseTimeLeft <= 0 || inactivityTimeLeft <= 0) {
                clearInterval(interval); // Stop the interval
                deleteCookie();          // Clear all cookies
                navigate('/login?action=clean-cookie&reason=session_expired');      // Redirect to the login page
            } else {
                // Optionally log the time left for each session (for debugging purposes)
                if (!isNaN(firebaseTimeLeft)) {
                    //        console.log(`Firebase time left: ${Math.floor(firebaseTimeLeft)} seconds`);
                }
                if (!isNaN(inactivityTimeLeft)) {
                    //        console.log(`Inactivity time left: ${Math.floor(inactivityTimeLeft)} seconds`);
                }
            }
        }, 1000);

        // This will be called when the component unmounts
        return () => {
            clearInterval(interval); // Clear the interval
            // Remove event listeners to prevent memory leaks
            window.removeEventListener('click', handleUserAction);
            window.removeEventListener('keydown', handleUserAction);
        };
    }, [navigate]); // Only re-run the effect if navigate changes

    return null; // This component doesn't render anything
};

function LoggedInWrapper({ dashboard_structure, PageContent }) {
    return (
        <div>
            {React.cloneElement(dashboard_structure, { children: PageContent })}
        </div>
    );
}

export const changeLanguage = (language) => {
    document.cookie = `language=${language}; path=/; samesite=strict`;
    window.location.reload();
}

export const setCookieValue = (name, value) => {
    document.cookie = `${name}=${value}; path=/; samesite=strict`;
};

export const getCookieValue = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const deleteCookie = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
    }
};
const App = () => {

    const [APIPageData, setAPIPageData] = useState(null);

    const handleUpdateNotificationBell = (PageData) => {
        if (document.getElementById('page_dashboard')) {
            if (PageData && typeof PageData === 'object') {
                setAPIPageData(PageData);
                return PageData;
            } else {
                console.log("PageData is not an object.");
            }
        } else {
            console.log("Element with ID 'page_dashboard' does not exist.");
        }
        return 0;
    };

    return (
        <Router>
            <div className="app-wrapper"> {/* Add this wrapper div */}
                <SessionManager />
                <LanguageFlagWrapper />
                <Routes>
                    <Route path="/" element={<RedirectIfNoCookie />} />
                    <Route path="/login" element={<Page_Login />} />
                    <Route path="/login_otp" element={<Page_Login_otp />} />
                    <Route
                        path="/dashboard"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Dashboard onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/Fornitori"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Suppliers onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />

                    <Route
                        path="/customers"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Customers onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/charts"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Charts onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/pl"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Pl onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/master-account"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Masteraccount onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/super-admin-operations"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_SuperAdminOperations onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/kyc-status"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Kycstatus onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/infrastructure-status"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Infrastructurestatus onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/maintenance"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Maintenance onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/unlimit"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Unlimit onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                    <Route
                        path="/epay"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_Epay onDataChange={handleUpdateNotificationBell} />}
                            />

                        }
                    />
                    <Route
                        path="/daily-outputs"
                        element={
                            <LoggedInWrapper
                                dashboard_structure={<Structure_logged APIPageData={APIPageData} />}
                                PageContent={<Page_DailyOutputs onDataChange={handleUpdateNotificationBell} />}
                            />
                        }
                    />
                </Routes>

            </div>
        </Router>
    );
}



// Initialize Firebase
initializeApp(firebaseConfig);

export default App;