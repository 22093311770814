import React, { useState, useEffect, useMemo } from 'react';
import {
  Grid, Card, CardContent, Typography, ThemeProvider, MenuItem, Select,
  FormControl, InputLabel, Table, TableBody, Button, TableCell, TableContainer,
  TableRow, Paper, Box, TableHead, TextField,
} from '@mui/material';
import { theme } from './ReactStyles';
import { fetchPageDashboard } from './Util_API_calls';
import { t } from './Util_format';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Loading from './Loading';
import Modal_UnrecognizedTransactions from './Modal_UnrecognizedTransactions';
import Modal_TopCardSpenders from './Modal_TopCardSpenders';
import Modal_NegativeBalanceUsers from './Modal_NegativeBalanceUsers';
import { subWeeks, subMonths, subYears, isWithinInterval, subDays, isValid } from 'date-fns';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { useCallback } from 'react';
import { parseISO, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import Modal_BlockedTransactions from './Modal_BlockedTransactions';
import Modal_CardRechargesBlocked from './Modal_CardRechargesBlocked';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'



function Page_Dashboard({ onDataChange, ...props }) {
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedPeriodUsersConnected, setselectedPeriodUsersConnected] = useState('this_month');
  const [selectedPeriodLogins, setSelectedPeriodLogins] = useState('this_week');
  const [selectedDayBankTransferOutgoing, setselectedDayBankTransferOutgoing] = useState('this_month');
  const [selectedPeriodKYC, setSelectedPeriodKYC] = useState('this_month');
  const [selectedPeriodPOSOperations, setSelectedPeriodPOSOperations] = useState('this_month');
  const [selectedPeriodAtmWithdrawals, setSelectedPeriodAtmWithdrawals] = useState('this_month');
  const [selectedPeriodFeesFromCards, setSelectedPeriodFeesFromCards] = useState('this_month');
  const [selectedPeriodCardEmissionFees, setSelectedPeriodCardEmissionFees] = useState('this_month');
  const [selectedPeriodTopCardSpenders, setSelectedPeriodTopCardSpenders] = useState('this_month');
  const [selectedPeriodUnrecognizedTransactions, setSelectedPeriodUnrecognizedTransactions] = useState('this_month');
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [topSpenders, setTopSpenders] = useState([]);
  const [topSpendersModalOpen, setTopSpendersModalOpen] = useState(false);
  const [selectedPeriodVoucherWithPins, setSelectedPeriodVoucherWithPins] = useState('this_month');
  const [selectedPeriodRegisteredUsers, setSelectedPeriodRegisteredUsers] = useState('this_month');
  const [selectedPeriodCashRecharges, setSelectedPeriodCashRecharges] = useState('this_year');
  const [selectedPeriodCardMaintenanceFees, setSelectedPeriodCardMaintenanceFees] = useState('this_month');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // 1-12 for specific months, 0 for "Tutti"
  const [selectedPeriodActiveCards, setSelectedPeriodActiveCards] = useState('this_month');
  const [userConnectionViewMode, setUserConnectionViewMode] = useState('last_30_days');
  const [userConnectionSelectedYear, setUserConnectionSelectedYear] = useState(new Date().getFullYear());
  const [userConnectionSelectedMonth, setUserConnectionSelectedMonth] = useState(new Date().getMonth() + 1);
  const [chartData, setChartData] = useState([]);
  const [activeUsersViewMode, setActiveUsersViewMode] = useState('last_30_days');
  const [activeUsersSelectedYear, setActiveUsersSelectedYear] = useState(new Date().getFullYear());
  const [activeUsersSelectedMonth, setActiveUsersSelectedMonth] = useState(new Date().getMonth() + 1);
  const [activeUsersChartData, setActiveUsersChartData] = useState([]);
  const [kycViewMode, setKycViewMode] = useState('last_30_days');
  const [kycSelectedYear, setKycSelectedYear] = useState(new Date().getFullYear());
  const [kycSelectedMonth, setKycSelectedMonth] = useState(new Date().getMonth() + 1);
  const [displayedKycStatuses, setDisplayedKycStatuses] = useState(['all']);
  const [selectedYearWhite, setSelectedYearWhite] = useState(new Date().getFullYear());
  const [blockedTransactionsModalOpen, setBlockedTransactionsModalOpen] = useState(false);
  const [cardRechargesModalOpen, setCardRechargesModalOpen] = useState(false);
  const [showLoginDescription, setShowLoginDescription] = useState(false);
  const [showActiveUsersDescription, setShowActiveUsersDescription] = useState(false);
  const [selectedMonthWhite, setSelectedMonthWhite] = useState(new Date().getMonth() + 1);
  const [showMonthlyActiveCustomersDescription, setShowMonthlyActiveCustomersDescription] = useState(false);
  const [monthlyActiveCustomersViewMode, setMonthlyActiveCustomersViewMode] = useState('last_12_months');
  const [monthlyActiveCustomersSelectedYear, setMonthlyActiveCustomersSelectedYear] = useState(new Date().getFullYear());
  const getTotalWhiteFees = (fees) => {
    return Object.values(fees).reduce((sum, fee) => sum + fee, 0);
  };

  const calculateWhiteFees = (data) => {
    if (!data || !data.bankaccount_maintenance_fees) return {};

    const result = {};
    data.bankaccount_maintenance_fees.forEach(({ year_month, total_amount }) => {
      const [year, month] = year_month.split('-').map(Number);
      if (year === selectedYearWhite && (selectedMonthWhite === 0 || month === selectedMonthWhite)) {
        result[year_month] = total_amount;
      }
    });
    return result;
  };

  const handleOpenCardRechargesModal = () => {
    setCardRechargesModalOpen(true);
  };

  const handleCloseCardRechargesModal = () => {
    setCardRechargesModalOpen(false);
  };

  const handleOpenBlockedTransactionsModal = () => {
    setBlockedTransactionsModalOpen(true);
  };

  const handleCloseBlockedTransactionsModal = () => {
    setBlockedTransactionsModalOpen(false);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState(null);

  const handleSearch = () => {
    if (!searchTerm) {
      setSearchResult(null);
      return;
    }

    const regex = new RegExp(searchTerm, 'gi');
    const matches = JSON.stringify(dashboardData, null, 2).match(regex);

    if (matches) {
      setSearchResult(`Found ${matches.length} occurrences`);
    } else {
      setSearchResult('No matches found');
    }
  };


  const kycChartData = useMemo(() => {
    if (!dashboardData || !dashboardData.kyc) return [];

    let startDate, endDate;

    if (kycViewMode === 'last_30_days') {
      endDate = new Date();  // Today
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 29);
    } else if (kycViewMode === 'specific_month') {
      startDate = new Date(kycSelectedYear, kycSelectedMonth - 1, 2);  // Start on the 2nd day of the selected month
      endDate = endOfMonth(startDate);  // End of selected month
    }

    const filteredData = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateStr = d.toISOString().split('T')[0];

      // Ensure the date falls within the selected month if in 'specific_month' mode
      if (kycViewMode === 'specific_month' && (d.getMonth() + 1) !== kycSelectedMonth) {
        continue;  // Skip dates not in the selected month
      }

      const dayData = dashboardData.kyc[dateStr] || {
        total_init: 0,
        total_prechecked: 0,
        total_queued: 0,
        total_pending: 0,
        total_onhold: 0,
        total_blocked: 0,
        total_rejected: 0,
        total_approved: 0
      };

      filteredData.push({
        date: dateStr,
        init: dayData.total_init,
        prechecked: dayData.total_prechecked,
        queued: dayData.total_queued,
        pending: dayData.total_pending,
        onhold: dayData.total_onhold,
        blocked: dayData.total_blocked,
        rejected: dayData.total_rejected,
        approved: dayData.total_approved
      });
    }

    return filteredData;
  }, [dashboardData, kycViewMode, kycSelectedYear, kycSelectedMonth]);



  const loadActiveUsersChartData = useCallback(() => {
    if (dashboardData && dashboardData.customer_activity) {
      let filteredData = [];
      let endDate, startDate;

      if (activeUsersViewMode === 'last_30_days') {
        endDate = new Date();
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 29);
      } else if (activeUsersViewMode === 'specific_month') {
        startDate = new Date(activeUsersSelectedYear, activeUsersSelectedMonth - 1, 1);
        endDate = new Date(activeUsersSelectedYear, activeUsersSelectedMonth, 0);
      }

      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const dateStr = d.toISOString().split('T')[0];
        filteredData.push({
          date: dateStr,
          count: dashboardData.customer_activity[dateStr]?.active_users || 0
        });
      }

      setActiveUsersChartData(filteredData);
    }
  }, [dashboardData, activeUsersViewMode, activeUsersSelectedYear, activeUsersSelectedMonth]);

  useEffect(() => {
    loadActiveUsersChartData();
  }, [loadActiveUsersChartData]);

  const handleActiveUsersViewModeChange = (event) => {
    setActiveUsersViewMode(event.target.value);
    loadActiveUsersChartData();
  };

  const handleActiveUsersMonthYearChange = (event) => {
    if (event.target.name === 'month') {
      setActiveUsersSelectedMonth(Number(event.target.value));
    } else if (event.target.name === 'year') {
      setActiveUsersSelectedYear(Number(event.target.value));
    }
    loadActiveUsersChartData();
  };


  const calculateApprovedClients = (kycData) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    const counts = {
      today: 0,
      yesterday: 0,
      this_week: 0,
      last_week: 0,
      this_month: 0,
      last_month: 0,
      this_year: 0,
      last_year: 0,
    };

    Object.entries(kycData).forEach(([dateStr, dayData]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            counts[key] += dayData.total_approved || 0;
          }
        });
      }
    });

    return counts;
  };


  const handleYearChange = (event) => {
    setSelectedYear(Number(event.target.value));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(Number(event.target.value));
  };



  const handlePeriodCardMaintenanceFeesChange = (event) => {
    setSelectedPeriodCardMaintenanceFees(event.target.value);
  };

  const handlePeriodCashRechargesChange = (event) => {
    setSelectedPeriodCashRecharges(event.target.value);
  };
  // Add this with your other handler functions
  const handlePeriodRegisteredUsersChange = (event) => {
    setSelectedPeriodRegisteredUsers(event.target.value);
  };

  const handlePeriodVoucherWithPinsChange = (event) => {
    setSelectedPeriodVoucherWithPins(event.target.value);
  };


  const [selectedPeriodBankTransfers, setSelectedPeriodBankTransfers] = useState('this_month');

  const handlePeriodBankTransfersChange = (event) => {
    setSelectedPeriodBankTransfers(event.target.value);
  };

  const [selectedPeriodPhoneRecharges, setSelectedPeriodPhoneRecharges] = useState('this_month');

  const handlePeriodPhoneRechargesChange = (event) => {
    setSelectedPeriodPhoneRecharges(event.target.value);
  };

  {/* abbonamenti attivi tile  tile */ }

  const calculateActiveCards = (data, selectedPeriod) => {
    const stats = {
      whiteCards: 0,
      blueCards: 0,
      totalCards: 0,
    };

    if (!data || !data.blue || !data.white) {
      console.error('Invalid data structure for active cards');
      return stats;
    }

    const processCardType = (cardData, isBlue) => {
      Object.entries(cardData).forEach(([dateStr, dayData]) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;

        if (
          (selectedPeriod === 'this_year' && year === new Date().getFullYear()) ||
          (selectedPeriod === 'this_month' && year === new Date().getFullYear() && month === new Date().getMonth() + 1) ||
          (selectedPeriod === 'last_month' &&
            ((year === new Date().getFullYear() && month === new Date().getMonth()) ||
              (year === new Date().getFullYear() - 1 && month === 12 && new Date().getMonth() === 0)))
        ) {
          const cardCount = isBlue ? dayData.quantity_of_blue_active_cards : dayData.quantity_of_white_active_cards;
          if (isBlue) {
            stats.blueCards = Math.max(stats.blueCards, cardCount || 0);
          } else {
            stats.whiteCards = Math.max(stats.whiteCards, cardCount || 0);
          }
        }
      });
    };

    processCardType(data.blue, true);
    processCardType(data.white, false);

    stats.totalCards = stats.whiteCards + stats.blueCards;

    return stats;
  };


  {/* Card counts and status tile */ }

  const calculateCardStatusCounts = (data, startDate, endDate) => {
    const result = {
      white_plastic: { ACTIVE: 0, INACTIVE: 0, BLOCKED: 0, ISSUE_PENDING: 0, TERMINATED: 0 },
      white_virtual: { ACTIVE: 0, INACTIVE: 0, BLOCKED: 0, ISSUE_PENDING: 0, TERMINATED: 0 },
      blue_plastic: { ACTIVE: 0, INACTIVE: 0, BLOCKED: 0, ISSUE_PENDING: 0, TERMINATED: 0 },
      blue_virtual: { ACTIVE: 0, INACTIVE: 0, BLOCKED: 0, ISSUE_PENDING: 0, TERMINATED: 0 }
    };

    Object.entries(data).forEach(([dateStr, dateData]) => {
      const date = new Date(dateStr);
      if (date >= startDate && date <= endDate) {
        Object.entries(dateData).forEach(([cardType, statusData]) => {
          Object.entries(statusData).forEach(([status, counts]) => {
            if (status !== 'UNKNOWN' && result[cardType] && result[cardType][status] !== undefined) {
              result[cardType][status] += counts.card_count;
            }
          });
        });
      }
    });

    return result;
  };
  {/* Utenti con ultimo accesso giornaliero tile  */ }


  const prepareDailyUserConnectionsData = (userData) => {
    if (!userData) return [];
    return Object.entries(userData).map(([dateStr, count]) => ({
      date: new Date(dateStr).getTime(),
      count: count
    })).sort((a, b) => a.date - b.date);
  };

  const calculateDailyUserConnections = (userData) => {
    if (!userData) return {};
    const dailyCounts = {};
    Object.entries(userData).forEach(([dateStr, count]) => {
      const date = new Date(dateStr);
      const key = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      dailyCounts[key] = count;
    });
    return dailyCounts;
  };

  const handleUserConnectionViewModeChange = (event) => {
    setUserConnectionViewMode(event.target.value);
    loadChartData();
  };

  const handleUserConnectionMonthYearChange = (event) => {
    if (event.target.name === 'month') {
      setUserConnectionSelectedMonth(Number(event.target.value));
    } else if (event.target.name === 'year') {
      setUserConnectionSelectedYear(Number(event.target.value));
    }
    loadChartData();
  };


  const loadChartData = useCallback(() => {
    if (dashboardData && dashboardData.customer_activity) {
      let filteredData = [];
      let endDate, startDate;

      if (userConnectionViewMode === 'last_30_days') {
        endDate = new Date();
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 29);
      } else if (userConnectionViewMode === 'specific_month') {
        startDate = new Date(userConnectionSelectedYear, userConnectionSelectedMonth - 1, 1);
        endDate = new Date(userConnectionSelectedYear, userConnectionSelectedMonth, 0);
      }

      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const dateStr = d.toISOString().split('T')[0];
        filteredData.push({
          date: dateStr,
          count: dashboardData.customer_activity[dateStr]?.customer_logins || 0
        });
      }

      setChartData(filteredData);
    }
  }, [dashboardData, userConnectionViewMode, userConnectionSelectedYear, userConnectionSelectedMonth]);


  const calculateUserConnections = (userData) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
    };

    const counts = {
      today: 0,
      yesterday: 0,
      this_week: 0,
      last_week: 0,
      this_month: 0,
      last_month: 0,
    };

    Object.entries(userData).forEach(([dateStr, count]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            counts[key] += count;
          }
        });
      }
    });

    return counts;
  };

  useEffect(() => {
    loadChartData();
  }, [loadChartData, userConnectionViewMode, userConnectionSelectedYear, userConnectionSelectedMonth]);

  {/* Utenti registrati tile  */ }


  const handleOpenTopSpendersModal = () => {
    setTopSpenders(topCardSpendersForSelectedPeriod);
    setTopSpendersModalOpen(true);
  };

  const handlePeriodActiveCardsChange = (event) => {
    setSelectedPeriodActiveCards(event.target.value);
  };

  const handleCloseTopSpendersModal = () => {
    setTopSpendersModalOpen(false);
  };

  const handleOpenModal = () => {
    setTransactions(dashboardData.unrecognised_transactions || []);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [negativeBalanceUsersModalOpen, setNegativeBalanceUsersModalOpen] = useState(false);
  const [negativeBalanceUsers, setNegativeBalanceUsers] = useState([]);

  const handleOpenNegativeBalanceUsersModal = () => {
    setNegativeBalanceUsers(dashboardData.users_negative_balances);
    setNegativeBalanceUsersModalOpen(true);
  };

  const handleCloseNegativeBalanceUsersModal = () => {
    setNegativeBalanceUsersModalOpen(false);
  };



  {/* Bank transfers (bonifici sepa tile) quantity and number tile  */ }

  const calculateBankTransferStats = (data) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    if (!data || !Array.isArray(data)) {
      return {
        today: { count: 0, fees: 0 },
        yesterday: { count: 0, fees: 0 },
        this_week: { count: 0, fees: 0 },
        last_week: { count: 0, fees: 0 },
        this_month: { count: 0, fees: 0 },
        last_month: { count: 0, fees: 0 },
        this_year: { count: 0, fees: 0 },
        last_year: { count: 0, fees: 0 },
      };
    }

    const stats = {
      today: { count: 0, fees: 0 },
      yesterday: { count: 0, fees: 0 },
      this_week: { count: 0, fees: 0 },
      last_week: { count: 0, fees: 0 },
      this_month: { count: 0, fees: 0 },
      last_month: { count: 0, fees: 0 },
      this_year: { count: 0, fees: 0 },
      last_year: { count: 0, fees: 0 },
    };

    data.forEach((item) => {
      const date = new Date(item.transaction_date);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            stats[key].count += item.count_transactions;
            stats[key].fees += item.sum_fee_amount_transaction;
          }
        });
      }
    });

    return stats;
  };


  {/* Totale vouchers tile  */ }

  const calculateVoucherStats = (data) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    const stats = {
      today: { count: 0, total: 0 },
      yesterday: { count: 0, total: 0 },
      this_week: { count: 0, total: 0 },
      last_week: { count: 0, total: 0 },
      this_month: { count: 0, total: 0 },
      last_month: { count: 0, total: 0 },
      this_year: { count: 0, total: 0 },
      last_year: { count: 0, total: 0 },
    };

    if (!data) return stats;

    Object.entries(data).forEach(([dateStr, dayData]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            stats[key].count += dayData.count_transactions;
            stats[key].total += parseFloat(dayData.total_amount);
          }
        });
      }
    });

    return stats;
  };


  {/* Totale Transazioni carte tile */ }

  const calculateCardOperationsStats = (data) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    const defaultStats = {
      total_transactions: 0,
      total_atm_withdrawal_amount: 0,
      count_atm_withdrawal: 0,
      total_pos_operation_amount: 0,
      count_pos_operation: 0,
      total_electronic_operation_amount: 0,
      count_electronic_operation: 0,
      total_p2p_debit_part_amount: 0,
      count_p2p_debit_part: 0,
      total_fees_amount: 0
    };

    const stats = {
      today: { ...defaultStats },
      this_week: { ...defaultStats },
      last_week: { ...defaultStats },
      this_month: { ...defaultStats },
      last_month: { ...defaultStats },
      this_year: { ...defaultStats },
      last_year: { ...defaultStats }
    };

    if (!data) return stats;

    Object.entries(data).forEach(([dateStr, dayData]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            Object.entries(dayData).forEach(([field, value]) => {
              if (field in defaultStats) {
                stats[key][field] += value;
              }
            });
          }
        });
      }
    });

    return stats;
  };


  {/* Card counts and status tile */ }

  const CardStatusDisplay = ({ title, counts }) => (
    <Card style={{ height: '100%', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
      <CardContent style={{ textAlign: 'center' }}>
        <Typography variant="h6" component="div" style={{ color: '#339AD8', marginBottom: '10px' }}>
          {title}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <CheckCircleIcon style={{ color: 'green' }} />
                    </Grid>
                    <Grid item>
                      Attive: {counts.ACTIVE}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <InfoIcon style={{ color: 'blue' }} />
                    </Grid>
                    <Grid item>
                      Inattive: {counts.INACTIVE}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <ErrorIcon style={{ color: 'red' }} />
                    </Grid>
                    <Grid item>
                      Bloccate: {counts.BLOCKED}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <WarningIcon style={{ color: 'orange' }} />
                    </Grid>
                    <Grid item>
                      Issue Pending: {counts.ISSUE_PENDING}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <ErrorIcon style={{ color: 'purple' }} />
                    </Grid>
                    <Grid item>
                      Terminate: {counts.TERMINATED}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );


  const translateCardOperationField = (field) => {
    const translations = {
      total_transactions: 'Totale transazioni',
      total_atm_withdrawal_amount: 'Importo totale prelievi ATM',
      count_atm_withdrawal: 'Numero prelievi ATM',
      total_pos_operation_amount: 'Importo totale operazioni POS',
      count_pos_operation: 'Numero operazioni POS',
      total_electronic_operation_amount: 'Importo totale operazioni elettroniche',
      count_electronic_operation: 'Numero operazioni elettroniche',
      total_p2p_debit_part_amount: 'Importo totale debiti P2P',
      count_p2p_debit_part: 'Numero debiti P2P',
      total_fees_amount: 'Importo totale commissioni'
    };
    return translations[field] || field;
  };


  const calculateKycCounts = (kycData) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      since_beginning: { start: new Date(0), end: today },
    };

    const counts = {
      today: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
      yesterday: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
      this_week: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
      last_week: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
      this_month: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
      last_month: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
      this_year: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
      since_beginning: { status_100: 0, status_99: 0, status_90: 0, status_8: 0, status_less_than_8: 0 },
    };

    Object.entries(kycData).forEach(([dateStr, dayData]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            Object.entries(dayData).forEach(([status, count]) => {
              counts[key][status] += count;
            });
          }
        });
      }
    });

    return counts;
  };

  {/* Totale ricariche cash tile */ }

  const calculateCashRechargeStats = (data) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    const stats = {
      today: { count: 0, total: 0 },
      yesterday: { count: 0, total: 0 },
      this_week: { count: 0, total: 0 },
      last_week: { count: 0, total: 0 },
      this_month: { count: 0, total: 0 },
      last_month: { count: 0, total: 0 },
      this_year: { count: 0, total: 0 },
      last_year: { count: 0, total: 0 },
    };

    if (!data) return stats;

    Object.entries(data).forEach(([dateStr, dayData]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            stats[key].count += dayData.count_transactions;
            stats[key].total += parseFloat(dayData.total_amount);
          }
        });
      }
    });

    return stats;
  };


  {/* Tot ricariche telefoniche tile  */ }

  const calculatePhoneRechargeStats = (data) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    const stats = {
      today: { count: 0, total: 0 },
      yesterday: { count: 0, total: 0 },
      this_week: { count: 0, total: 0 },
      last_week: { count: 0, total: 0 },
      this_month: { count: 0, total: 0 },
      last_month: { count: 0, total: 0 },
      this_year: { count: 0, total: 0 },
      last_year: { count: 0, total: 0 },
    };

    if (!data) return stats;

    Object.entries(data).forEach(([dateStr, dayData]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            stats[key].count += dayData.count_transactions;
            stats[key].total += parseFloat(dayData.total_amount);
          }
        });
      }
    });

    return stats;
  };

  {/* Totale fees dalle carte blue  */ }



  const calculateBlueFees = (data) => {
    if (!data || !data.blue) return {};

    const result = {};
    Object.entries(data.blue).forEach(([dateStr, fee]) => {
      const [year, month] = dateStr.split('-').map(Number);
      if (year === selectedYear && (selectedMonth === 0 || month === selectedMonth)) {
        result[dateStr] = fee;
      }
    });
    return result;
  };

  const getTotalBlueFees = (fees) => {
    return Object.values(fees).reduce((sum, fee) => sum + fee, 0);
  };

  const calculateCardMaintenanceFees = (data) => {
    const calculateTotalFees = (cardType, startDate, endDate) => {
      return Object.entries(data[cardType] || {}).reduce((sum, [dateStr, fee]) => {
        const date = new Date(dateStr);
        if (date >= startDate && date <= endDate) {
          return sum + fee;
        }
        return sum;
      }, 0);
    };


    {/* Totale fees dalle carte white */ }



    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    const yesterday = new Date(now.setDate(now.getDate() - 1));

    const periods = {
      this_year: [startOfYear, now],
      last_month: [new Date(now.getFullYear(), now.getMonth() - 1, 1), new Date(now.getFullYear(), now.getMonth(), 0)],
      this_month: [startOfMonth, now],
      last_week: [new Date(startOfWeek.setDate(startOfWeek.getDate() - 7)), new Date(startOfWeek.setDate(startOfWeek.getDate() + 6))],
      this_week: [startOfWeek, now],
      today: [now, now],
    };

    const result = {};
    for (const [period, [start, end]] of Object.entries(periods)) {
      const whiteFees = calculateTotalFees('white', start, end);
      const blueFees = calculateTotalFees('blue', start, end);
      result[period] = {
        whiteFees,
        blueFees,
        totalFees: whiteFees + blueFees,
      };
    }

    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await fetchPageDashboard();
      if (result.status === 'success') {
        setDashboardData({
          ...result.stats,
          epay_reconciliation_numbers: result.epay_reconciliation_numbers,
          bank_transfers_processing_stuck: result.bank_transfers_processing_stuck,
          card_recharges_processing_stuck: result.card_recharges_processing_stuck,
          role: result.role
        });
      } else {
        console.error('Error fetching dashboard data:', result.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [])


  if (loading || !dashboardData) {
    return <Loading />;
  }


  {/* Totale spedizioni carte fisiche tile */ }

  const calculateCardEmissionFees = (data, period) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    const stats = {
      totalWhite: 0,
      totalBlue: 0
    };

    if (!data) return stats;

    Object.entries(data).forEach(([dateStr, dayData]) => {
      const date = new Date(dateStr);
      if (isValid(date) && isWithinInterval(date, intervals[period])) {
        stats.totalWhite += (dayData.amount_card_plastic_white || 0) + (dayData.amount_card_virtual_white || 0);
        stats.totalBlue += (dayData.amount_card_plastic_blue || 0) + (dayData.amount_card_virtual_blue || 0);
      }
    });

    return stats;
  };


  {/* Utenti registrati tile  */ }
  const calculateRegisteredUsersCounts = (usersData) => {
    const today = new Date();
    const intervals = {
      today: { start: startOfDay(today), end: today },
      yesterday: { start: startOfDay(subDays(today, 1)), end: startOfDay(today) },
      this_week: { start: startOfWeek(today), end: today },
      last_week: { start: startOfWeek(subWeeks(today, 1)), end: startOfWeek(today) },
      this_month: { start: startOfMonth(today), end: today },
      last_month: { start: startOfMonth(subMonths(today, 1)), end: startOfMonth(today) },
      this_year: { start: startOfYear(today), end: today },
      last_year: { start: startOfYear(subYears(today, 1)), end: startOfYear(today) },
    };

    const counts = {
      today: 0,
      yesterday: 0,
      this_week: 0,
      last_week: 0,
      this_month: 0,
      last_month: 0,
      this_year: 0,
      last_year: 0,

    };

    if (!usersData) return counts;

    Object.entries(usersData).forEach(([dateStr, count]) => {
      const date = new Date(dateStr);
      if (isValid(date)) {
        Object.entries(intervals).forEach(([key, interval]) => {
          if (isWithinInterval(date, interval)) {
            counts[key] += count;
          }
        });
      }
    });

    return counts;
  };


  const getTotalPosOperations = (data, period) => {
    if (!data || !data[period]) return { totalAmount: 0, count: 0 };
    return data[period].reduce((acc, item) => {
      acc.totalAmount += item.total_pos_operation_amount;
      acc.count += item.count_pos_operation;
      return acc;
    }, { totalAmount: 0, count: 0 });
  };

  const getTotalAtmWithdrawals = (data, period) => {
    if (!data || !data[period]) return { totalAmount: 0, count: 0 };
    return data[period].reduce((acc, item) => {
      acc.totalAmount += item.total_atm_withdrawal_amount;
      acc.count += item.count_atm_withdrawal;
      return acc;
    }, { totalAmount: 0, count: 0 });
  };

  const getTotalFeesFromCards = (data, period) => {
    if (!data || !data[period]) return 0;
    return data[period].reduce((acc, item) => acc + item.total_fees_amount, 0);
  };

  const getTotalCardMaintenanceFees = (data, cardType, period) => {
    if (!data[cardType] || !data[cardType][period]) return 0;
    return data[cardType][period].reduce((acc, item) => acc + item.total_fees_on_white_active_cards || item.total_fees_on_blue_active_cards || 0, 0);
  };

  const getTotalCardEmissionFees = (data, period) => {
    if (!data || !data[period]) return { totalWhite: 0, totalBlue: 0 };
    return data[period].reduce((acc, item) => {
      acc.totalWhite += (item.amount_card_plastic_white || 0) + (item.amount_card_virtual_white || 0);
      acc.totalBlue += (item.amount_card_plastic_blue || 0) + (item.amount_card_virtual_blue || 0);
      return acc;
    }, { totalWhite: 0, totalBlue: 0 });
  };

  const getTopCardSpenders = (data, period) => {
    if (!data || !data[period]) return [];
    return Array.isArray(data[period]) ? data[period] : [];
  };

  const getTotalUnrecognizedTransactions = (data) => {
    if (!data || !data.unrecognised_transactions) return 0;
    return data.unrecognised_transactions.length;
  };

  const userRole = dashboardData?.role;


  const kycStatusColors = {
    init: '#00bcd4',     // darker cyan
    queued: '#9c27b0',   // darker purple
    pending: '#1a237e',  // darker navy blue
    prechecked: '#2196f3', // darker blue
    approved: '#4caf50', // darker green
    onhold: '#795548',   // darker brown
    rejected: '#d32f2f', // darker red
    blocked: '#ff5722',  // darker orange
  };

  const statusTranslations = {
    all: 'Tutti',
    init: 'Iniziati',
    queued: 'In coda',
    pending: 'Pendenti',
    prechecked: 'Preapprovati',
    approved: 'Approvati',
    onhold: 'Sospesi',
    rejected: 'Respinti',
    blocked: 'Bloccati'
  };

  const handleKycViewModeChange = (event) => {
    setKycViewMode(event.target.value);
  };

  const handleKycMonthYearChange = (event) => {
    if (event.target.name === 'month') {
      setKycSelectedMonth(Number(event.target.value));
    } else if (event.target.name === 'year') {
      setKycSelectedYear(Number(event.target.value));
    }
  };

  const handleDisplayedKycStatusesChange = (event) => {
    const selectedValue = event.target.value;
    const lastSelected = selectedValue[selectedValue.length - 1];

    if (lastSelected === 'all') {
      setDisplayedKycStatuses(['all']);
    } else {
      const newStatuses = selectedValue.filter(status => status !== 'all');
      setDisplayedKycStatuses(newStatuses.length > 0 ? newStatuses : ['all']);
    }
  };

  const handlePeriodUsersConnectedChange = (event) => {
    setselectedPeriodUsersConnected(event.target.value);
  };

  const handlePeriodLoginsChange = (event) => {
    setSelectedPeriodLogins(event.target.value);
  };

  const handlePeriodKYCChange = (event) => {
    setSelectedPeriodKYC(event.target.value);
  };

  const handlePeriodBankTransferOutgoingChange = (event) => {
    setselectedDayBankTransferOutgoing(event.target.value);
  };

  const handlePeriodCashRecharges = (event) => {
    setSelectedPeriodCashRecharges(event.target.value);
  };

  const handlePeriodPOSOperationsChange = (event) => {
    setSelectedPeriodPOSOperations(event.target.value);
  };

  const handlePeriodAtmWithdrawalsChange = (event) => {
    setSelectedPeriodAtmWithdrawals(event.target.value);
  };

  const handlePeriodFeesFromCardsChange = (event) => {
    setSelectedPeriodFeesFromCards(event.target.value);
  };

  const handlePeriodCardEmissionFeesChange = (event) => {
    setSelectedPeriodCardEmissionFees(event.target.value);
  };

  const handlePeriodTopCardSpendersChange = (event) => {
    setSelectedPeriodTopCardSpenders(event.target.value);
  };

  const handlePeriodUnrecognizedTransactionsChange = (event) => {
    setSelectedPeriodUnrecognizedTransactions(event.target.value);
  };


  const getTotalBankTransfersFees = (data, period) => {
    if (!data[period]) return 0;
    return data[period].reduce((acc, item) => acc + item.sum_fee_amount_transaction, 0);
  };

  const getTotalBankTransfersCount = (data, period) => {
    if (!data[period]) return 0;
    return data[period].reduce((acc, item) => acc + item.count_transactions, 0);
  };

  const getTotalPhoneRecharges = (data, period) => {
    if (!data || !data[period]) return 0;
    return Object.values(data[period]).reduce((acc, item) => acc + item.count_transactions, 0);
  };

  const getTotalVoucherWithPins = (data, period) => {
    if (!data || !data[period]) return 0;
    return data[period].reduce((acc, item) => acc + item.count_transactions, 0);
  };

  const getTotalCashRecharges = (data, period) => {
    if (!data || !data[period]) return 0;
    return data[period].length;
  };

  const totalBankTransfersForSelectedPeriod = getTotalBankTransfersFees(dashboardData.bank_transfers?.outgoing_banktransfers_fees || [], selectedDayBankTransferOutgoing);
  const totalBankTransfersCountForSelectedPeriod = getTotalBankTransfersCount(dashboardData.bank_transfers?.outgoing_banktransfers_fees || [], selectedDayBankTransferOutgoing);
  const totalPhoneRechargesForSelectedPeriod = getTotalPhoneRecharges(dashboardData.epay_operations?.phone_recharges || {}, selectedPeriodPhoneRecharges);
  const totalVoucherWithPinsForSelectedPeriod = getTotalVoucherWithPins(dashboardData.epay_operations.vouchers_with_pin, selectedPeriodVoucherWithPins);
  const totalCashRechargesForSelectedPeriod = getTotalCashRecharges(dashboardData.epay_operations.bankaccount_cash_recharges, selectedPeriodCashRecharges);
  const totalCardMaintenanceFeesBlueLastMonth = dashboardData.card_maintenance_fees.blue.last_month ?? 0;
  const totalCardMaintenanceFeesWhiteLastMonth = dashboardData.card_maintenance_fees.white.last_month ?? 0;
  const totalPosOperationsForSelectedPeriod = getTotalPosOperations(dashboardData.card_operations, selectedPeriodPOSOperations);
  const totalAtmWithdrawalsForSelectedPeriod = getTotalAtmWithdrawals(dashboardData.card_operations, selectedPeriodAtmWithdrawals);
  const totalFeesFromCardsForSelectedPeriod = getTotalFeesFromCards(dashboardData.card_operations, selectedPeriodFeesFromCards);
  const totalCardMaintenanceFeesBlueForSelectedPeriod = getTotalCardMaintenanceFees(dashboardData.card_maintenance_fees, 'blue', selectedPeriodCardMaintenanceFees);
  const totalCardMaintenanceFeesWhiteForSelectedPeriod = getTotalCardMaintenanceFees(dashboardData.card_maintenance_fees, 'white', selectedPeriodCardMaintenanceFees);
  const totalCardEmissionFeesForSelectedPeriod = getTotalCardEmissionFees(dashboardData.card_emission_fees, selectedPeriodCardEmissionFees);
  const topCardSpendersForSelectedPeriod = getTopCardSpenders(dashboardData.top_50_card_spenders, selectedPeriodTopCardSpenders);
  const totalUnrecognizedTransactionsForSelectedPeriod = getTotalUnrecognizedTransactions(dashboardData.epay_reconciliation_numbers);

  const fidoAmount = dashboardData?.fido_epay?.fido_amount ?? null;
  const unlimitBalance = dashboardData?.unlimit?.master_account_last_balance ?? null;


  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>


        {/*   

      {userRole === 'basic_viewer' && (
  <Grid item xs={12} style={{ 
    position: 'fixed', 
    top: 0, 
    left: 0, 
    right: 0, 
    bottom: 0, 
    zIndex: 9999, 
    backgroundColor: 'white' 
  }}>
    <Card style={{ 
      height: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      boxShadow: 'none' 
    }}>
      <CardContent>
        <Typography variant="h2" style={{ color: 'red', textAlign: 'center' }}>
          Servizio sospeso per questioni amministrative
        </Typography>
      </CardContent>
    </Card>
  </Grid>
)} 

*/ }

        <Grid container spacing={3} style={{ padding: '5px' }}>
          {fidoAmount !== null && fidoAmount < 2500 && (
            <Grid item xs={12} style={{ padding: '15px' }}>
              <Box style={{
                backgroundColor: '#EF5350',
                color: 'white',
                padding: '6px',
                textAlign: 'center'
              }}>
                <Typography variant="h6">
                  Bilancio Conto Epay Basso: {new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(fidoAmount)}
                </Typography>
              </Box>
            </Grid>
          )}
          {userRole === 'super_admin' && unlimitBalance < 2500 && (
            <Grid item xs={12} style={{ padding: '15px' }}>
              <Box style={{ backgroundColor: '#EF5350', color: 'white', padding: '6px', textAlign: 'center' }}>
                <Typography variant="h6">Bilancio conto master Unlimit basso: Euro {unlimitBalance !== null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(unlimitBalance) : 'N/A'}</Typography>
              </Box>
            </Grid>
          )}

          {/* Bilancio Account fido tile  */}

          <Grid item xs={12} >
            <Card style={{ height: '150px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Bilancio account Fido epay*
                </Typography>
                <Typography variant="h3" component="div" style={{ marginTop: '10px' }}>
                  {fidoAmount !== null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(fidoAmount) : 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* TBilancio unlimit master account tile  */}

          {userRole === 'super_admin' && (
            <Grid item xs={12}>
              <Card style={{ height: '150px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                    Bilancio Unlimit master account*
                  </Typography>
                  <Typography variant="h3" component="div" style={{ marginTop: '10px' }}>
                    {unlimitBalance !== null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(unlimitBalance) : 'N/A'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}


          {/* Totale Login Giornalieri* tile  */}

          <Grid item xs={12} sm={11} style={{ margin: '0 auto' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: '100%', margin: '20px auto' }}>
              <CardContent style={{ padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                  <Typography variant="h5" component="div" style={{ color: '#339AD8', textAlign: 'center' }}>
                    Totale Login Giornalieri*
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => setShowLoginDescription(true)}
                    style={{ marginLeft: '10px', color: '#339AD8' }}
                  >
                    Descrizione
                  </Button>
                </div>

                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="user-connection-view-mode-select-label" shrink>
                        Visualizza
                      </InputLabel>
                      <Select
                        labelId="user-connection-view-mode-select-label"
                        id="user-connection-view-mode-select"
                        value={userConnectionViewMode}
                        onChange={handleUserConnectionViewModeChange}
                        label="Visualizza"
                        notched
                      >
                        <MenuItem value="last_30_days">Ultimi 30 giorni</MenuItem>
                        <MenuItem value="specific_month">Mese specifico</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {userConnectionViewMode === 'specific_month' && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="select-user-connection-month-label" shrink>
                            Mese
                          </InputLabel>
                          <Select
                            labelId="select-user-connection-month-label"
                            id="select-user-connection-month"
                            name="month"
                            value={userConnectionSelectedMonth}
                            onChange={handleUserConnectionMonthYearChange}
                            label="Mese"
                            notched
                          >
                            {Array.from({ length: 12 }, (_, i) => (
                              <MenuItem key={i + 1} value={i + 1}>
                                {new Date(0, i).toLocaleString('it-IT', { month: 'long' })}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="select-user-connection-year-label" shrink>
                            Anno
                          </InputLabel>
                          <Select
                            labelId="select-user-connection-year-label"
                            id="select-user-connection-year"
                            name="year"
                            value={userConnectionSelectedYear}
                            onChange={handleUserConnectionMonthYearChange}
                            label="Anno"
                            notched
                          >
                            {Array.from({ length: 5 }, (_, i) => (
                              <MenuItem key={i} value={new Date().getFullYear() - i}>
                                {new Date().getFullYear() - i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
                {chartData.length > 0 ? (
                  <div style={{ height: 300, width: '100%', marginTop: '20px' }}>
                    <BarChart
                      width={undefined}
                      height={250}
                      series={[
                        {
                          data: chartData.map(item => item.count),
                          type: 'bar',
                        },
                      ]}
                      xAxis={[{
                        data: chartData.map(item => item.date),
                        scaleType: 'band',
                        tickFormatter: (value) => {
                          const date = new Date(value);
                          return `${date.getDate()}/${date.getMonth() + 1}`;
                        },
                      }]}
                      yAxis={[{ min: 0 }]}
                      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    />
                    <Typography variant="body2" style={{ textAlign: 'center', marginTop: '10px' }}>
                      Numero utenti
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'right', marginTop: '10px' }}>
                      *Sono escluse le sessioni mobili gia autenticate.
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1" style={{ marginTop: '20px', textAlign: 'center' }}>
                    Nessun dato disponibile
                  </Typography>
                )}
              </CardContent>
            </Card>

            <Dialog
              open={showLoginDescription}
              onClose={() => setShowLoginDescription(false)}
              maxWidth="md"
            >
              <DialogTitle style={{ color: '#339AD8' }}>
                Dettagli Totale Login Giornalieri
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                  Questo grafico mostra il numero totale di accessi giornalieri effettuati dagli utenti attraverso l'interfaccia web.
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                  È importante notare che:
                </Typography>
                <ul style={{ marginLeft: '20px' }}>
                  <li style={{ marginBottom: '8px' }}>
                    Gli accessi vengono conteggiati solo quando un utente effettua il login tramite browser desktop o mobile.
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    Le sessioni attive dall'applicazione mobile non vengono conteggiate nuovamente finché l'utente non effettua un nuovo login.
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    L'app mobile mantiene la sessione attiva per comodità dell'utente, quindi gli accessi ripetuti non vengono conteggiati come nuovi login.
                  </li>
                </ul>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowLoginDescription(false)} color="primary">
                  Chiudi
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>


          {/* Accessi giornalieri degli utenti tile::: utenti che si sono collegati o che hanno fatto qualsiasi attivita in app e desktop  */}

          <Grid item xs={12} sm={11} style={{ margin: '0 auto' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: '100%', margin: '20px auto' }}>
              <CardContent style={{ padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                  <Typography variant="h5" component="div" style={{ color: '#339AD8', textAlign: 'center' }}>
                    Totale Utenti Connessi*
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => setShowActiveUsersDescription(true)}
                    style={{ marginLeft: '10px', color: '#339AD8' }}
                  >
                    Descrizione
                  </Button>
                </div>

                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="active-users-view-mode-select-label" shrink>
                        Visualizza
                      </InputLabel>
                      <Select
                        labelId="active-users-view-mode-select-label"
                        id="active-users-view-mode-select"
                        value={activeUsersViewMode}
                        onChange={handleActiveUsersViewModeChange}
                        label="Visualizza"
                        notched
                      >
                        <MenuItem value="last_30_days">Ultimi 30 giorni</MenuItem>
                        <MenuItem value="specific_month">Mese specifico</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {activeUsersViewMode === 'specific_month' && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="select-active-users-month-label" shrink>
                            Mese
                          </InputLabel>
                          <Select
                            labelId="select-active-users-month-label"
                            id="select-active-users-month"
                            name="month"
                            value={activeUsersSelectedMonth}
                            onChange={handleActiveUsersMonthYearChange}
                            label="Mese"
                            notched
                          >
                            {Array.from({ length: 12 }, (_, i) => (
                              <MenuItem key={i + 1} value={i + 1}>
                                {new Date(0, i).toLocaleString('it-IT', { month: 'long' })}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="select-active-users-year-label" shrink>
                            Anno
                          </InputLabel>
                          <Select
                            labelId="select-active-users-year-label"
                            id="select-active-users-year"
                            name="year"
                            value={activeUsersSelectedYear}
                            onChange={handleActiveUsersMonthYearChange}
                            label="Anno"
                            notched
                          >
                            {Array.from({ length: 5 }, (_, i) => (
                              <MenuItem key={i} value={new Date().getFullYear() - i}>
                                {new Date().getFullYear() - i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>

                {activeUsersChartData.length > 0 ? (
                  <div style={{ height: 300, width: '100%', marginTop: '20px' }}>
                    <BarChart
                      width={undefined}
                      height={250}
                      series={[
                        {
                          data: activeUsersChartData.map(item => item.count),
                          type: 'bar',
                        },
                      ]}
                      xAxis={[{
                        data: activeUsersChartData.map(item => item.date),
                        scaleType: 'band',
                        tickFormatter: (value) => {
                          const date = new Date(value);
                          return `${date.getDate()}/${date.getMonth() + 1}`;
                        },
                      }]}
                      yAxis={[{ min: 0 }]}
                      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    />
                    <Typography variant="body2" style={{ textAlign: 'center', marginTop: '10px' }}>
                      Numero Utenti
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1" style={{ marginTop: '20px', textAlign: 'center' }}>
                    Nessun dato disponibile
                  </Typography>
                )}
              </CardContent>
            </Card>

            <Dialog
              open={showActiveUsersDescription}
              onClose={() => setShowActiveUsersDescription(false)}
              maxWidth="md"
            >
              <DialogTitle style={{ color: '#339AD8' }}>
                Dettagli Totale Utenti Connessi
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                  Questo grafico rappresenta il numero totale di utenti che hanno interagito con i nostri servizi quotidianamente.
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                  Il conteggio include:
                </Typography>
                <ul style={{ marginLeft: '20px' }}>
                  <li style={{ marginBottom: '8px' }}>
                    Tutti gli utenti che hanno effettuato l'accesso tramite browser desktop o mobile
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    Tutti gli utenti che hanno utilizzato l'app mobile, sia con nuovi accessi che con sessioni già attive
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    Qualsiasi attività dell'utente, incluse operazioni passive come la consultazione del saldo o attive come l'esecuzione di transazioni
                  </li>
                </ul>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowActiveUsersDescription(false)} color="primary">
                  Chiudi
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          {/* Clienti Attivi con Transazioni Mensili tile::: utenti che hanno fatto transazioni nel mese */}

          <Grid item xs={12} sm={11} style={{ margin: '0 auto' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: '100%', margin: '20px auto' }}>
              <CardContent style={{ padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                  <Typography variant="h5" component="div" style={{ color: '#339AD8', textAlign: 'center' }}>
                    Clienti Attivi con Transazioni Mensili*
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => setShowMonthlyActiveCustomersDescription(true)}
                    style={{ marginLeft: '10px', color: '#339AD8' }}
                  >
                    Descrizione
                  </Button>
                </div>

                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="monthly-active-customers-view-mode-select-label" shrink>
                        Visualizza
                      </InputLabel>
                      <Select
                        labelId="monthly-active-customers-view-mode-select-label"
                        id="monthly-active-customers-view-mode-select"
                        value={monthlyActiveCustomersViewMode}
                        onChange={(e) => setMonthlyActiveCustomersViewMode(e.target.value)}
                        label="Visualizza"
                        notched
                      >
                        <MenuItem value="last_12_months">Ultimi 12 mesi</MenuItem>
                        <MenuItem value="specific_year">Anno specifico</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {monthlyActiveCustomersViewMode === 'specific_year' && (
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-monthly-active-customers-year-label" shrink>
                          Anno
                        </InputLabel>
                        <Select
                          labelId="select-monthly-active-customers-year-label"
                          id="select-monthly-active-customers-year"
                          value={monthlyActiveCustomersSelectedYear}
                          onChange={(e) => setMonthlyActiveCustomersSelectedYear(e.target.value)}
                          label="Anno"
                          notched
                        >
                          {Array.from({ length: 5 }, (_, i) => (
                            <MenuItem key={i} value={new Date().getFullYear() - i}>
                              {new Date().getFullYear() - i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>

                <div style={{ height: 300, width: '100%', marginTop: '20px' }}>
                  <BarChart
                    width={undefined}
                    height={250}
                    series={[
                      {
                        data: dashboardData.monthly_active_customers
                          .filter(item => {
                            if (monthlyActiveCustomersViewMode === 'last_12_months') {
                              const lastYear = new Date();
                              lastYear.setMonth(lastYear.getMonth() - 11);
                              return new Date(item.period) >= lastYear;
                            } else {
                              return item.period.startsWith(monthlyActiveCustomersSelectedYear);
                            }
                          })
                          .map(item => item.unique_customers_count),
                        type: 'bar',
                      },
                    ]}
                    xAxis={[{
                      data: dashboardData.monthly_active_customers
                        .filter(item => {
                          if (monthlyActiveCustomersViewMode === 'last_12_months') {
                            const lastYear = new Date();
                            lastYear.setMonth(lastYear.getMonth() - 11);
                            return new Date(item.period) >= lastYear;
                          } else {
                            return item.period.startsWith(monthlyActiveCustomersSelectedYear);
                          }
                        })
                        .map(item => {
                          const [year, month] = item.period.split('-');
                          return new Date(year, month - 1).toLocaleString('it-IT', { month: 'short' });
                        }),
                      scaleType: 'band',
                    }]}
                    yAxis={[{ min: 0 }]}
                    margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                  />
                  <Typography variant="body2" style={{ textAlign: 'center', marginTop: '10px' }}>
                    Numero Clienti
                  </Typography>
                </div>
              </CardContent>
            </Card>

            <Dialog
              open={showMonthlyActiveCustomersDescription}
              onClose={() => setShowMonthlyActiveCustomersDescription(false)}
              maxWidth="md"
            >
              <DialogTitle style={{ color: '#339AD8' }}>
                Dettagli Clienti Attivi con Transazioni Mensili
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                  Questo grafico mostra il numero di clienti che hanno generato ricavi attraverso transazioni nel corso di ogni mese.
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                  Include:
                </Typography>
                <ul style={{ marginLeft: '20px' }}>
                  <li style={{ marginBottom: '8px' }}>
                    Clienti che hanno effettuato almeno una transazione monetaria
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    Tutte le operazioni che generano commissioni o ricavi
                  </li>
                  <li style={{ marginBottom: '8px' }}>
                    Transazioni come bonifici, ricariche, pagamenti e altre operazioni a valore aggiunto
                  </li>
                </ul>
                <Typography variant="body1" style={{ marginTop: '16px' }}>
                  Questo indicatore è fondamentale per monitorare il livello di attività effettiva e la generazione di ricavi della nostra base clienti.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowMonthlyActiveCustomersDescription(false)} color="primary">
                  Chiudi
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>


          {/* Stato clienti acquistati tile */}

          <Grid item xs={12} sm={11} style={{ margin: '0 auto' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: '100%', margin: '20px auto' }}>
              <CardContent style={{ padding: '20px' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8', marginBottom: '20px', textAlign: 'center' }}>
                  Stato clienti acquisiti*
                </Typography>

                {/* Existing KYC Chart */}
                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="kyc-view-mode-select-label" shrink>
                        Visualizza
                      </InputLabel>
                      <Select
                        labelId="kyc-view-mode-select-label"
                        id="kyc-view-mode-select"
                        value={kycViewMode}
                        onChange={handleKycViewModeChange}
                        label="Visualizza"
                        notched
                      >
                        <MenuItem value="last_30_days">Ultimi 30 giorni</MenuItem>
                        <MenuItem value="specific_month">Mese specifico</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="displayed-kyc-statuses-label" shrink>
                        Stati visualizzati
                      </InputLabel>
                      <Select
                        labelId="displayed-kyc-statuses-label"
                        id="displayed-kyc-statuses-select"
                        multiple
                        value={displayedKycStatuses}
                        onChange={handleDisplayedKycStatusesChange}
                        label="Stati visualizzati"
                        notched
                        renderValue={(selected) => selected.includes('all') ? 'Tutti' : selected.map(s => statusTranslations[s]).join(', ')}
                      >
                        <MenuItem value="all">Tutti</MenuItem>
                        {Object.keys(kycStatusColors).map(status => (
                          <MenuItem key={status} value={status}>{statusTranslations[status]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {kycViewMode === 'specific_month' && (
                    <>
                      <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="select-kyc-month-label" shrink>
                            Mese
                          </InputLabel>
                          <Select
                            labelId="select-kyc-month-label"
                            id="select-kyc-month"
                            name="month"
                            value={kycSelectedMonth}
                            onChange={handleKycMonthYearChange}
                            label="Mese"
                            notched
                          >
                            {Array.from({ length: 12 }, (_, i) => (
                              <MenuItem key={i + 1} value={i + 1}>
                                {new Date(0, i).toLocaleString('it-IT', { month: 'long' })}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="select-kyc-year-label" shrink>
                            Anno
                          </InputLabel>
                          <Select
                            labelId="select-kyc-year-label"
                            id="select-kyc-year"
                            name="year"
                            value={kycSelectedYear}
                            onChange={handleKycMonthYearChange}
                            label="Anno"
                            notched
                          >
                            {Array.from({ length: 5 }, (_, i) => (
                              <MenuItem key={i} value={new Date().getFullYear() - i}>
                                {new Date().getFullYear() - i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>

                {kycChartData.length > 0 ? (
                  <div style={{ height: '500px', width: '100%', marginTop: '20px' }}>
                    <BarChart
                      width={undefined}
                      height={450}
                      series={
                        displayedKycStatuses.includes('all')
                          ? Object.keys(kycStatusColors).map(status => ({
                            data: kycChartData.map(item => item[status]),
                            label: statusTranslations[status],
                            stack: 'total',
                            color: kycStatusColors[status],
                          }))
                          : displayedKycStatuses.map(status => ({
                            data: kycChartData.map(item => item[status]),
                            label: statusTranslations[status],
                            stack: 'total',
                            color: kycStatusColors[status],
                          }))
                      }
                      xAxis={[{
                        data: kycChartData.map(item => item.date),
                        scaleType: 'band',
                        tickFormatter: (value) => {
                          const date = new Date(value);
                          return `${date.getDate()}/${date.getMonth() + 1}`;
                        },
                      }]}
                      yAxis={[{ min: 0 }]}
                      margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
                      slotProps={{
                        legend: {
                          direction: 'row',
                          position: { vertical: 'top', horizontal: 'middle' },
                          padding: { bottom: 20 },
                        },
                      }}
                    />
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '10px',
                      paddingLeft: '40px',
                      paddingRight: '10px'
                    }}>
                      {kycChartData.map((dayData, index) => {
                        const total = Object.keys(kycStatusColors).reduce((sum, status) => sum + (dayData[status] || 0), 0);
                        return (
                          <div key={index} style={{ textAlign: 'center', flex: 1 }}>
                            <Typography variant="caption">
                              Totale<br />{total}
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <Typography variant="body1" style={{ marginTop: '20px', textAlign: 'center' }}>
                    Nessun dato disponibile
                  </Typography>
                )}

                {/* Approved Clients Calculator */}
                <div style={{ marginTop: '40px', textAlign: 'center' }}>
                  <Typography variant="h6" style={{ color: '#339AD8', marginBottom: '20px' }}>
                    Clienti Registrati (Approvati)
                  </Typography>
                  <FormControl variant="outlined" style={{ width: '200px', marginBottom: '20px' }}>
                    <InputLabel id="approved-clients-period-label">Periodo</InputLabel>
                    <Select
                      labelId="approved-clients-period-label"
                      value={selectedPeriodKYC}
                      onChange={handlePeriodKYCChange}
                      label="Periodo"
                    >
                      <MenuItem value="today">{t('today')}</MenuItem>
                      <MenuItem value="yesterday">{t('yesterday')}</MenuItem>
                      <MenuItem value="this_week">{t('this_week')}</MenuItem>
                      <MenuItem value="last_week">{t('last_week')}</MenuItem>
                      <MenuItem value="this_month">{t('this_month')}</MenuItem>
                      <MenuItem value="last_month">{t('last_month')}</MenuItem>
                      <MenuItem value="this_year">{t('this_year')}</MenuItem>
                      <MenuItem value="last_year">{t('last_year')}</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="body1" style={{ marginBottom: '10px' }}>
                    Clienti acquisiti {t(selectedPeriodKYC)}:
                  </Typography>
                  <Typography variant="h4">
                    {calculateApprovedClients(dashboardData.kyc)[selectedPeriodKYC]}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>


          {/* Bank transfers (bonifici sepa tile) quantity and number tile  */}

          <Grid item xs={12} sm={6}>
            <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Bonifici SEPA emessi*{' '}
                  {selectedPeriodBankTransfers === 'today' && `${t('today').toLowerCase()}`}
                  {selectedPeriodBankTransfers === 'yesterday' && `${t('yesterday').toLowerCase()}`}
                  {selectedPeriodBankTransfers === 'this_week' && `${t('this_week').toLowerCase()}`}
                  {selectedPeriodBankTransfers === 'last_week' && `${t('last_week').toLowerCase()}`}
                  {selectedPeriodBankTransfers === 'this_month' && `${t('this_month').toLowerCase()}`}
                  {selectedPeriodBankTransfers === 'last_month' && `${t('last_month').toLowerCase()}`}
                  {selectedPeriodBankTransfers === 'this_year' && `${t('this_year').toLowerCase()}`}
                  {selectedPeriodBankTransfers === 'last_year' && `${t('last_year').toLowerCase()}`}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                  Valore fees: {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(calculateBankTransferStats(dashboardData.bank_transfers?.outgoing_banktransfers_fees || [])[selectedPeriodBankTransfers].fees)}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                  Numero Transazioni: {calculateBankTransferStats(dashboardData.bank_transfers?.outgoing_banktransfers_fees || [])[selectedPeriodBankTransfers].count}
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '32px', minWidth: 200 }}>
                  <InputLabel id="select-period-bank-transfers-label">{t('change_period')}</InputLabel>
                  <Select
                    labelId="select-period-bank-transfers-label"
                    value={selectedPeriodBankTransfers}
                    onChange={handlePeriodBankTransfersChange}
                    label="Seleziona periodo"
                  >
                    <MenuItem value="today">{t('today')}</MenuItem>
                    <MenuItem value="yesterday">{t('yesterday')}</MenuItem>
                    <MenuItem value="this_week">{t('this_week')}</MenuItem>
                    <MenuItem value="last_week">{t('last_week')}</MenuItem>
                    <MenuItem value="this_month">{t('this_month')}</MenuItem>
                    <MenuItem value="last_month">{t('last_month')}</MenuItem>
                    <MenuItem value="this_year">{t('this_year')}</MenuItem>
                    <MenuItem value="last_year">{t('last_year')}</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>

          {/* Tot ricariche telefoniche tile  */}

          <Grid item xs={12} sm={6}>
            <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Totale ricariche telefoniche*{' '}
                  {selectedPeriodPhoneRecharges === 'today' && `${t('today').toLowerCase()}`}
                  {selectedPeriodPhoneRecharges === 'yesterday' && `${t('yesterday').toLowerCase()}`}
                  {selectedPeriodPhoneRecharges === 'this_week' && `${t('this_week').toLowerCase()}`}
                  {selectedPeriodPhoneRecharges === 'last_week' && `${t('last_week').toLowerCase()}`}
                  {selectedPeriodPhoneRecharges === 'this_month' && `${t('this_month').toLowerCase()}`}
                  {selectedPeriodPhoneRecharges === 'last_month' && `${t('last_month').toLowerCase()}`}
                  {selectedPeriodPhoneRecharges === 'last_year' && `${t('last_year').toLowerCase()}`}
                  {selectedPeriodPhoneRecharges === 'this_year' && `${t('this_year').toLowerCase()}`}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                  Numero transazioni: {calculatePhoneRechargeStats(dashboardData.epay_operations?.phone_recharges || {})[selectedPeriodPhoneRecharges].count}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                  Valore totale: {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(calculatePhoneRechargeStats(dashboardData.epay_operations?.phone_recharges || {})[selectedPeriodPhoneRecharges].total)}
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '32px', minWidth: 200 }}>
                  <InputLabel id="select-period-phone-recharges-label">{t('change_period')}</InputLabel>
                  <Select
                    labelId="select-period-phone-recharges-label"
                    value={selectedPeriodPhoneRecharges}
                    onChange={handlePeriodPhoneRechargesChange}
                    label="Seleziona periodo"
                  >
                    <MenuItem value="today">{t('today')}</MenuItem>
                    <MenuItem value="yesterday">{t('yesterday')}</MenuItem>
                    <MenuItem value="this_week">{t('this_week')}</MenuItem>
                    <MenuItem value="last_week">{t('last_week')}</MenuItem>
                    <MenuItem value="last_month">{t('last_month')}</MenuItem>
                    <MenuItem value="this_month">{t('this_month')}</MenuItem>
                    <MenuItem value="last_year">{t('last_year')}</MenuItem>
                    <MenuItem value="this_year">{t('this_year')}</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>



          {/* Card counts and status tile */}
          <Grid item xs={12}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent>
                <Typography variant="h5" component="div" style={{ color: '#339AD8', marginBottom: '20px', textAlign: 'center' }}>
                  Carte numeri e status* - {selectedMonth === 0
                    ? `${selectedYear} (Tutti i mesi)`
                    : new Date(selectedYear, selectedMonth - 1).toLocaleString('it-IT', { month: 'long', year: 'numeric' })}
                </Typography>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Card Type</TableCell>
                        <TableCell align="center">
                          <CheckCircleIcon style={{ color: 'green' }} />
                          <Typography>ACTIVE</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <InfoIcon style={{ color: 'blue' }} />
                          <Typography>INACTIVE</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <ErrorIcon style={{ color: 'red' }} />
                          <Typography>BLOCKED</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <WarningIcon style={{ color: 'orange' }} />
                          <Typography>ISSUE PENDING</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <ErrorIcon style={{ color: 'purple' }} />
                          <Typography>TERMINATED</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(() => {
                        const startDate = new Date(selectedYear, selectedMonth === 0 ? 0 : selectedMonth - 1, 1);
                        const endDate = selectedMonth === 0
                          ? new Date(selectedYear, 11, 31)
                          : new Date(selectedYear, selectedMonth, 0);
                        const counts = calculateCardStatusCounts(dashboardData.card_count_and_status, startDate, endDate);
                        return ['white_plastic', 'white_virtual', 'blue_plastic', 'blue_virtual'].map((cardType) => (
                          <TableRow key={cardType}>
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                {cardType.replace('_', ' ').toUpperCase()}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">{counts[cardType].ACTIVE}</TableCell>
                            <TableCell align="center">{counts[cardType].INACTIVE}</TableCell>
                            <TableCell align="center">{counts[cardType].BLOCKED}</TableCell>
                            <TableCell align="center">{counts[cardType].ISSUE_PENDING}</TableCell>
                            <TableCell align="center">{counts[cardType].TERMINATED}</TableCell>
                          </TableRow>
                        ));
                      })()}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '20px', marginTop: '5px' }}>
                  <FormControl variant="outlined" style={{ minWidth: 120 }}>
                    <InputLabel id="select-year-label">Anno</InputLabel>
                    <Select
                      labelId="select-year-label"
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Anno"
                    >
                      {[...Array(5)].map((_, i) => (
                        <MenuItem key={i} value={new Date().getFullYear() - i}>
                          {new Date().getFullYear() - i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" style={{ minWidth: 120 }}>
                    <InputLabel id="select-month-label">Mese</InputLabel>
                    <Select
                      labelId="select-month-label"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      label="Mese"
                    >
                      <MenuItem value={0}>Tutti</MenuItem>
                      {[...Array(12)].map((_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          {new Date(0, i).toLocaleString('it-IT', { month: 'long' })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </CardContent>
            </Card>
          </Grid>



          {/* Totale fees dalle carte blue */}

          <Grid item xs={12} sm={6}>
            <Card style={{ height: 'auto', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Tot Fee dalle carte Blu*
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                  Totale: {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(getTotalBlueFees(calculateBlueFees(dashboardData.card_maintenance_fees)))}
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '20px', minWidth: 120 }}>
                  <InputLabel id="select-year-label">Anno</InputLabel>
                  <Select
                    labelId="select-year-label"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    label="Anno"
                  >
                    {[...Array(5)].map((_, i) => (
                      <MenuItem key={i} value={new Date().getFullYear() - i}>
                        {new Date().getFullYear() - i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" style={{ marginTop: '20px', marginLeft: '20px', minWidth: 120 }}>
                  <InputLabel id="select-month-label">Mese</InputLabel>
                  <Select
                    labelId="select-month-label"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    label="Mese"
                  >
                    <MenuItem value={0}>Tutti</MenuItem>
                    {[...Array(12)].map((_, i) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {new Date(0, i).toLocaleString('it-IT', { month: 'long' })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>

          {/* Totale fees dalle carte white */}

          {/* Totale fees dalle carte white */}

          <Grid item xs={12} sm={6}>
            <Card style={{ height: 'auto', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Tot Fee dalle carte Bianche*
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                  Totale: {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(getTotalWhiteFees(calculateWhiteFees(dashboardData)))}
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '20px', minWidth: 120 }}>
                  <InputLabel id="select-year-white-label">Anno</InputLabel>
                  <Select
                    labelId="select-year-white-label"
                    value={selectedYearWhite}
                    onChange={(e) => setSelectedYearWhite(e.target.value)}
                    label="Anno"
                  >
                    {[...Array(5)].map((_, i) => (
                      <MenuItem key={i} value={new Date().getFullYear() - i}>
                        {new Date().getFullYear() - i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" style={{ marginTop: '20px', marginLeft: '20px', minWidth: 120 }}>
                  <InputLabel id="select-month-white-label">Mese</InputLabel>
                  <Select
                    labelId="select-month-white-label"
                    value={selectedMonthWhite}
                    onChange={(e) => setSelectedMonthWhite(e.target.value)}
                    label="Mese"
                  >
                    <MenuItem value={0}>Tutti</MenuItem>
                    {[...Array(12)].map((_, i) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {new Date(0, i).toLocaleString('it-IT', { month: 'long' })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>


          {/* Totale spedizioni carte fisiche tile */}
          <Grid item xs={12} sm={6}>
            <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Totale spedizioni carta fisica*{' '}
                  {selectedPeriodCardEmissionFees === 'last_year' && `${t('last_year').toLowerCase()}`}
                  {selectedPeriodCardEmissionFees === 'this_year' && `${t('this_year').toLowerCase()}`}
                  {selectedPeriodCardEmissionFees === 'last_month' && `${t('last_month').toLowerCase()}`}
                  {selectedPeriodCardEmissionFees === 'this_month' && `${t('this_month').toLowerCase()}`}
                  {selectedPeriodCardEmissionFees === 'last_week' && `${t('last_week').toLowerCase()}`}
                  {selectedPeriodCardEmissionFees === 'this_week' && `${t('this_week').toLowerCase()}`}
                  {selectedPeriodCardEmissionFees === 'today' && `${t('today').toLowerCase()}`}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                  {`White: ${new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(calculateCardEmissionFees(dashboardData.card_emission_fees, selectedPeriodCardEmissionFees).totalWhite)}`}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                  {`Blue: ${new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(calculateCardEmissionFees(dashboardData.card_emission_fees, selectedPeriodCardEmissionFees).totalBlue)}`}
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '32px', minWidth: 200 }}>
                  <InputLabel id="select-period-card-emission-fees">{t('change_period')}</InputLabel>
                  <Select
                    labelId="select-period-card-emission-fees"
                    value={selectedPeriodCardEmissionFees}
                    onChange={handlePeriodCardEmissionFeesChange}
                    label="Seleziona periodo"
                  >
                    <MenuItem value="today">{t('today')}</MenuItem>
                    <MenuItem value="this_week">{t('this_week')}</MenuItem>
                    <MenuItem value="last_week">{t('last_week')}</MenuItem>
                    <MenuItem value="this_month">{t('this_month')}</MenuItem>
                    <MenuItem value="last_month">{t('last_month')}</MenuItem>
                    <MenuItem value="this_year">{t('this_year')}</MenuItem>
                    <MenuItem value="last_year">{t('last_year')}</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>

          {/* Totale vouchers tile */}

          <Grid item xs={12} sm={6}>
            <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Totale Vouchers{' '}
                  {selectedPeriodVoucherWithPins === 'today' && `${t('today').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'yesterday' && `${t('yesterday').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'this_week' && `${t('this_week').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'last_week' && `${t('last_week').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'this_month' && `${t('this_month').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'last_month' && `${t('last_month').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'this_year' && `${t('this_year').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'last_year' && `${t('last_year').toLowerCase()}`}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                  Numero transazioni: {calculateVoucherStats(dashboardData.epay_operations.vouchers_with_pin)[selectedPeriodVoucherWithPins].count}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                  Valore totale: {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(calculateVoucherStats(dashboardData.epay_operations.vouchers_with_pin)[selectedPeriodVoucherWithPins].total)}
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '32px', minWidth: 200 }}>
                  <InputLabel id="select-period-voucher-with-pin">{t('change_period')}</InputLabel>
                  <Select
                    labelId="select-period-voucher-with-pin"
                    value={selectedPeriodVoucherWithPins}
                    onChange={handlePeriodVoucherWithPinsChange}
                    label="Seleziona periodo"
                  >
                    <MenuItem value="today">{t('today')}</MenuItem>
                    <MenuItem value="yesterday">{t('yesterday')}</MenuItem>
                    <MenuItem value="this_week">{t('this_week')}</MenuItem>
                    <MenuItem value="last_week">{t('last_week')}</MenuItem>
                    <MenuItem value="this_month">{t('this_month')}</MenuItem>
                    <MenuItem value="last_month">{t('last_month')}</MenuItem>
                    <MenuItem value="this_year">{t('this_year')}</MenuItem>
                    <MenuItem value="last_year">{t('last_year')}</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>

          {/* Totale ricariche cash tile  */}


          <Grid item xs={12} sm={6}>
            <Card style={{ height: '1000', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Totale ricariche cash*{' '}
                  {selectedPeriodCashRecharges === 'today' && `${t('today').toLowerCase()}`}
                  {selectedPeriodCashRecharges === 'yesterday' && `${t('yesterday').toLowerCase()}`}
                  {selectedPeriodCashRecharges === 'this_week' && `${t('this_week').toLowerCase()}`}
                  {selectedPeriodCashRecharges === 'last_week' && `${t('last_week').toLowerCase()}`}
                  {selectedPeriodCashRecharges === 'this_month' && `${t('this_month').toLowerCase()}`}
                  {selectedPeriodCashRecharges === 'last_month' && `${t('last_month').toLowerCase()}`}
                  {selectedPeriodCashRecharges === 'this_year' && `${t('this_year').toLowerCase()}`}
                  {selectedPeriodVoucherWithPins === 'last_year' && `${t('last_year').toLowerCase()}`}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '30px' }}>
                  Numero transazioni: {calculateCashRechargeStats(dashboardData.epay_operations.bankaccount_cash_recharges)[selectedPeriodCashRecharges].count}
                </Typography>

                <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                  Valore totale: {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(calculateCashRechargeStats(dashboardData.epay_operations.bankaccount_cash_recharges)[selectedPeriodCashRecharges].total)}
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '32px', minWidth: 200 }}>
                  <InputLabel id="select-period-cash-recharges">{t('change_period')}</InputLabel>
                  <Select
                    labelId="select-period-cash-recharges"
                    value={selectedPeriodCashRecharges}
                    onChange={handlePeriodCashRechargesChange}
                    label="Seleziona periodo"
                  >
                    <MenuItem value="today">{t('today')}</MenuItem>
                    <MenuItem value="yesterday">{t('yesterday')}</MenuItem>
                    <MenuItem value="this_week">{t('this_week')}</MenuItem>
                    <MenuItem value="last_week">{t('last_week')}</MenuItem>
                    <MenuItem value="this_month">{t('this_month')}</MenuItem>
                    <MenuItem value="last_month">{t('last_month')}</MenuItem>
                    <MenuItem value="this_year">{t('this_year')}</MenuItem>
                    <MenuItem value="last_year">{t('last_year')}</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>


          {/* Dettaglio operazioni carte tile  */}

          <Grid item xs={12} sm={6} >
            <Card style={{ height: '1000', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Dettaglio operazioni carte*{' '}
                  {selectedPeriodFeesFromCards === 'this_year' && `${t('this_year').toLowerCase()}`}
                  {selectedPeriodFeesFromCards === 'last_month' && `${t('last_month').toLowerCase()}`}
                  {selectedPeriodFeesFromCards === 'this_month' && `${t('this_month').toLowerCase()}`}
                  {selectedPeriodFeesFromCards === 'last_week' && `${t('last_week').toLowerCase()}`}
                  {selectedPeriodFeesFromCards === 'this_week' && `${t('this_week').toLowerCase()}`}
                  {selectedPeriodFeesFromCards === 'today' && `${t('today').toLowerCase()}`}
                  {selectedPeriodFeesFromCards === 'last_year' && `${t('last_year').toLowerCase()}`}
                </Typography>

                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                  <Table size="small">
                    <TableBody>
                      {Object.entries(calculateCardOperationsStats(dashboardData.card_operations)[selectedPeriodFeesFromCards]).map(([key, value]) => {
                        // Adjust 'total_fees_amount' to match the exact key used for "Importo totale commissioni" in your data
                        if (key !== 'total_fees_amount') {
                          return (
                            <TableRow key={key}>
                              <TableCell component="th" scope="row">
                                {translateCardOperationField(key)}:
                              </TableCell>
                              <TableCell align="right">
                                {key.includes('amount') || key.includes('fees')
                                  ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value)
                                  : new Intl.NumberFormat('it-IT').format(value)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return null;
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <FormControl variant="outlined" style={{ marginTop: '32px', minWidth: 200 }}>
                  <InputLabel id="select-period-card-operations">{t('change_period')}</InputLabel>
                  <Select
                    labelId="select-period-card-operations"
                    value={selectedPeriodFeesFromCards}
                    onChange={handlePeriodFeesFromCardsChange}
                    label="Seleziona periodo"
                  >
                    <MenuItem value="today">{t('today')}</MenuItem>
                    <MenuItem value="this_week">{t('this_week')}</MenuItem>
                    <MenuItem value="last_week">{t('last_week')}</MenuItem>
                    <MenuItem value="this_month">{t('this_month')}</MenuItem>
                    <MenuItem value="last_month">{t('last_month')}</MenuItem>
                    <MenuItem value="this_year">{t('this_year')}</MenuItem>
                    <MenuItem value="last_year">{t('last_year')}</MenuItem>

                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>






          {/* Top 50 tile  */}

          <Grid item xs={12} sm={6}>
            <Card style={{ height: '570px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'auto', padding: '14px' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Top 50 clienti per transato carte*
                </Typography>

                <FormControl variant="outlined" style={{ marginTop: '10px', minWidth: 200 }}>
                  <InputLabel id="select-period-top-card-spenders">{t('change_period')}</InputLabel>
                  <Select
                    labelId="select-period-top-card-spenders"
                    value={selectedPeriodTopCardSpenders}
                    onChange={handlePeriodTopCardSpendersChange}
                    label="Seleziona periodo"
                  >
                    <MenuItem value="today">{t('today')}</MenuItem>
                    <MenuItem value="last_week">{t('last_week')}</MenuItem>
                    <MenuItem value="this_week">{t('this_week')}</MenuItem>
                    <MenuItem value="this_month">{t('this_month')}</MenuItem>
                    <MenuItem value="last_month">{t('last_month')}</MenuItem>
                    <MenuItem value="this_year">{t('this_year')}</MenuItem>
                    <MenuItem value="last_year">{t('last_year')}</MenuItem>
                  </Select>
                </FormControl>
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                  <Table size="small" aria-label="top card spenders">
                    <TableBody>
                      {Array.isArray(topCardSpendersForSelectedPeriod) ?
                        topCardSpendersForSelectedPeriod
                          .sort((a, b) => b.amount_spent - a.amount_spent)
                          .slice(0, 10)
                          .map((spender, index) => (
                            <TableRow key={index}>
                              <TableCell>{`${index + 1}:`}</TableCell>
                              <TableCell>{spender.first_name || 'N/A'}</TableCell>
                              <TableCell>{spender.middle_name || ' '}</TableCell>
                              <TableCell>{spender.last_name || 'N/A'}</TableCell>
                              <TableCell>
                                {new Intl.NumberFormat('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                }).format(spender.amount_spent)}
                              </TableCell>
                            </TableRow>
                          ))
                        : <TableRow><TableCell colSpan={5}>No data available</TableCell></TableRow>
                      }

                    </TableBody>
                  </Table>
                </TableContainer>


                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenTopSpendersModal}
                    style={{ marginTop: '20px' }}
                  >
                    Mostra Top 50 utenti
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>

          {/* Utenti bilancio negativo tile */}



          <Grid item xs={12} sm={6}>
            <Card style={{ height: '570px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'auto', padding: '14px' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Utenti con bilancio negativo**
                </Typography>

                <Typography variant="h3" component="div" style={{ marginTop: '5px' }}>
                  {dashboardData.users_negative_balances.length}
                </Typography>

                <TableContainer component={Paper} style={{ marginTop: '22px' }}>
                  <Table size="small" aria-label="negative balance users">
                    <TableBody>
                      {dashboardData.users_negative_balances
                        .sort((a, b) => a.last_known_balance - b.last_known_balance)
                        .slice(0, 10)
                        .map((user, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ textAlign: 'center' }}>{`${index + 1}:`}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{user.first_name}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{user.last_name}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {new Intl.NumberFormat('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              }).format(user.last_known_balance)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenNegativeBalanceUsersModal}
                  style={{ marginTop: '30px' }}
                >
                  Mostra Tutti Gli Utenti
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* abbonamenti attivi tile  tile */}


          {/*<Grid item xs={12} sm={6}>
  <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
    <CardContent style={{ textAlign: 'center' }}>
      <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
        Abbonamenti Attivi{' '}
        {selectedPeriodActiveCards === 'this_year' && `${t('this_year').toLowerCase()}`}
        {selectedPeriodActiveCards === 'last_month' && `${t('last_month').toLowerCase()}`}
        {selectedPeriodActiveCards === 'this_month' && `${t('this_month').toLowerCase()}`}
      </Typography>

      {(() => {
        const stats = calculateActiveCards(dashboardData.card_maintenance_fees, selectedPeriodActiveCards);
        return (
          <>
            <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
              Blu: {stats.blueCards} carte
            </Typography>
            <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
              White: {stats.whiteCards} carte
            </Typography>
          </>
        );
      })()}

      <FormControl variant="outlined" style={{ marginTop: '32px', minWidth: 200 }}>
        <InputLabel id="select-period-active-cards">{t('change_period')}</InputLabel>
        <Select
          labelId="select-period-active-cards"
          value={selectedPeriodActiveCards}
          onChange={handlePeriodActiveCardsChange}
          label="Seleziona periodo"
        >
          <MenuItem value="this_year">{t('this_year')}</MenuItem>
          <MenuItem value="last_month">{t('last_month')}</MenuItem>
          <MenuItem value="this_month">{t('this_month')}</MenuItem>
        </Select>
      </FormControl>
    </CardContent>
  </Card>
</Grid>*/}




          {/* Blocked Transactions tile  */}



          {userRole === 'super_admin' && (
            <Grid item xs={12} sm={6}>
              <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h5" component="div" style={{ color: '#EF5350' }}>
                    Transazioni bancarie rimaste bloccate
                  </Typography>
                  <Typography variant="h3" component="div" style={{ marginTop: '20px', color: '#EF5350' }}>
                    {dashboardData.bank_transfers_processing_stuck ? dashboardData.bank_transfers_processing_stuck.length : 0}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenBlockedTransactionsModal}
                    style={{ marginTop: '20px' }}
                  >
                    Mostra Dettagli
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}



          {/* Blocked card recharges tile  */}

          {userRole === 'super_admin' && (
            <Grid item xs={12} sm={6}>

              <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h5" component="div" style={{ color: '#EF5350' }}>
                    Ricariche carte rimaste bloccate
                  </Typography>
                  <Typography variant="h3" component="div" style={{ marginTop: '20px', color: '#EF5350' }}>
                    {dashboardData.card_recharges_processing_stuck ? dashboardData.card_recharges_processing_stuck.length : 0}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenCardRechargesModal}
                    style={{ marginTop: '20px' }}
                  >
                    Mostra Dettagli
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}


          {/* Reconciliazioni epay tile  */}

          {userRole === 'super_admin' && (
            <Grid item xs={12} sm={11} style={{ margin: '0 auto' }}>
              <Card style={{ height: '280px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h5" component="div" style={{ color: '#EF5350' }}>
                    Reconciliazione delle transazioni EPAY*
                  </Typography>

                  <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                    Bollettini: {dashboardData.epay_reconciliation_numbers?.summary?.bollettini896 || 0}
                  </Typography>

                  <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                    Voucher: {dashboardData.epay_reconciliation_numbers?.summary?.vouchers_with_pin || 0}
                  </Typography>

                  <Typography variant="h6" component="div" style={{ marginTop: '10px' }}>
                    Ricariche telefoniche: {dashboardData.epay_reconciliation_numbers?.summary?.phone_recharges || 0}
                  </Typography>

                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModal}
                    >
                      Mostra Transazioni
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}




          {/* Raw data tile  */}

          {userRole === 'super_admin' && (
            <Grid item xs={12}>
              <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                    Raw Dashboard Data
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                    <TextField
                      label="Search in data"
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ marginRight: '10px', width: '300px' }}
                    />
                    <Button variant="contained" color="primary" onClick={handleSearch}>
                      Search
                    </Button>
                  </div>
                  {searchResult && (
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      {searchResult}
                    </Typography>
                  )}
                  <pre style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    maxHeight: '500px',
                    overflowY: 'auto',
                    backgroundColor: '#f5f5f5',
                    padding: '10px',
                    textAlign: 'left'
                  }}>
                    {JSON.stringify(dashboardData, null, 2)}
                  </pre>
                </CardContent>
              </Card>
            </Grid>
          )}


          {/* Maintenance Message Tile */}
          <Grid item xs={12}>
            <Card style={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              margin: '5px '
            }}>
              <CardContent style={{ textAlign: 'center', padding: '20px' }}>
                <Typography variant="h6" component="div" style={{
                  color: 'black',
                  fontSize: '1rem'  // Adjust this value to make the text bigger or smaller
                }}>
                  * Dato aggiornato ogni 10 minuti <br />
                  ** Dato aggiornato ogni 24 ore
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* end of last tile */}

          <Modal_TopCardSpenders
            open={topSpendersModalOpen}
            handleClose={handleCloseTopSpendersModal}
            transactions={topSpenders}
          />

          <Modal_NegativeBalanceUsers
            open={negativeBalanceUsersModalOpen}
            handleClose={handleCloseNegativeBalanceUsersModal}
            users={negativeBalanceUsers}
          />

          <Modal_UnrecognizedTransactions
            open={modalOpen}
            handleClose={handleCloseModal}
            transactions={transactions}
          />

          <Modal_BlockedTransactions
            open={blockedTransactionsModalOpen}
            handleClose={handleCloseBlockedTransactionsModal}
            transactions={Array.isArray(dashboardData.bank_transfers_processing_stuck) ? dashboardData.bank_transfers_processing_stuck : []}
          />

          <Modal_CardRechargesBlocked
            open={cardRechargesModalOpen}
            handleClose={handleCloseCardRechargesModal}
            recharges={Array.isArray(dashboardData.card_recharges_processing_stuck) ? dashboardData.card_recharges_processing_stuck : []}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
}


export default Page_Dashboard;
